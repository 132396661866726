.switch {
    width: 180px;
    height: 55px;
    position: relative;
    margin: 20px auto;
  }
  
  .switch input {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    outline: none; /* Убираем стандартную обводку */
    box-shadow: none; /* Убираем тень */
  }
  
  /* Убираем обводку при фокусе на интерактивных элементах */
.switch input:focus {
    outline: none; /* Убирает обводку */
    box-shadow: none; /* Убирает тень */
  }
  
  /* Убираем специфическую обводку для Safari и iOS */
  .switch input:focus-visible {
    outline: none;
    box-shadow: none;
  }
  
  /* Полное отключение всех видов фокуса */
  .switch input:focus,
  .switch input:focus-visible,
  .switch input:active {
    outline: none !important;
    box-shadow: none !important;
  }

  * {
    -webkit-tap-highlight-color: transparent; /* Убираем выделение */
  }
  
  .switch label {
    display: block;
    width: 80%;
    height: 100%;
    position: relative;
    background: #1f2736;
    background: linear-gradient(#121823, #161d2b);
    border-radius: 30px;
    box-shadow: inset 0 3px 8px 1px rgba(0, 0, 0, 0.5),
      inset 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.2);
    transition: all 0.5s ease;
    outline: none; /* Убираем стандартную обводку */

  }
  
  .switch input ~ label i {
    display: block;
    height: 51px;
    width: 51px;
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 2;
    border-radius: inherit;
    background: #283446;
    background: linear-gradient(#36455b, #283446);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
      0 0 8px rgba(0, 0, 0, 0.3), 0 12px 12px rgba(0, 0, 0, 0.4);
    transition: all 0.5s ease;
    outline: none; /* Убираем стандартную обводку */

  }
  
  .switch input:checked ~ label i {
    left: 63%;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
      0 0 8px rgba(0, 0, 0, 0.3), 0 8px 8px rgba(0, 0, 0, 0.3),
      inset -1px 0 1px #b9f3fe;
    transition: all 0.5s ease;
    outline: none; /* Убираем стандартную обводку */

  }
  
  .switch label + span {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 17px;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #283446;
    background: linear-gradient(#36455b, #283446);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2),
      0 1px 0 rgba(255, 255, 255, 0.1), 0 0 10px rgba(185, 231, 253, 0),
      inset 0 0 8px rgba(0, 0, 0, 0.9), inset 0 -2px 5px rgba(0, 0, 0, 0.3),
      inset 0 -5px 5px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    outline: none; /* Убираем стандартную обводку */

  }
  
  .switch input:checked ~ label + span {
    background: #b9f3fe;
    background: linear-gradient(#ffffff, #77a1b9);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1),
      0 1px 0 rgba(255, 255, 255, 0.1), 0 0 10px rgba(100, 231, 253, 1),
      inset 0 0 8px rgba(61, 157, 247, 0.8),
      inset 0 -2px 5px rgba(185, 231, 253, 0.3),
      inset 0 -3px 8px rgba(185, 231, 253, 0.5);
    transition: all 0.5s ease;
    outline: none; /* Убираем стандартную обводку */

  }
  